import axios from "axios";
import { Capacitor } from "@capacitor/core";
import { LOGIN_USER } from "./store/actions/actionTypes/types";
import { AuthItemsInterface, UserInfoInterface } from "./types";
import { LogoutOperations } from "./Helper/LogoutHelper";
import { RefreshTokenExpiredException } from "./Exception/RefreshTokenExpiredException";
import { BUILD_TARGET } from "./index";
import { WINDOW_NAMES } from "./overwolf/app/constants";
import { getCurrentWindow } from "./utils";
import packageJson from "../package.json";

export const phoneStringFormatter = (phoneNumber: string = "") => {
  if (phoneNumber?.length === 10) {
    return `1-${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }
  if (phoneNumber?.length === 11) {
    return `${phoneNumber.slice(0, 1)}-${phoneNumber.slice(
      1,
      4
    )}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
  } else return phoneNumber;
};

export const generateConfig = (accessToken: string | undefined) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  };
};

export const generateLanguageConfig = (
  accessToken: string | undefined,
  language: string,
) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Accept-Language": language === "en" ? "en-US;" : "fr-CA;",
    },
  };
};

export const hideWord = (word: string) => {
  return "*".repeat(word?.length)
}


export const countryParser = (country: string) => {
  if (country === "UNITED_STATES") {
    return "USA";
  }
  if (country === "CANADA") {
    return "Canada";
  } else return country;
};

export const refreshTokenHandler = async (
  refreshToken: string,
  userInfo: UserInfoInterface,
  dispatch: any,
  navigate: any,
  noLogoutOn401?: boolean
) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const refreshTokenRequest = {
    application: await GetApplication(),
    applicationVersion: GetApplicationVersion(),
    refreshToken
  };
  try {
    const refreshTokenResponse = await axios.patch(
      `${BASE_URL}/api/auth/refresh`,
      refreshTokenRequest
    );
    if (refreshTokenResponse.status === 200) {
      const payloadObject: AuthItemsInterface = {
        accessToken: refreshTokenResponse.data.accessToken,
        userInfo,
        refreshToken: refreshTokenResponse.data.refreshToken,
      };
      const localStorageUserData = localStorage.getItem("user");
      if (!!localStorageUserData) {
        localStorage.setItem("user", JSON.stringify(payloadObject));
        dispatch({ type: LOGIN_USER, payload: payloadObject });
      } else {
        sessionStorage.setItem("user", JSON.stringify(payloadObject));
        dispatch({ type: LOGIN_USER, payload: payloadObject });
      }
      return payloadObject;
    }
  } catch (error: any) {
    if (error?.response?.status === 401 && !noLogoutOn401) {
      LogoutOperations(navigate);
      dispatch({ type: "LOGOUT_USER" });
      throw new RefreshTokenExpiredException("Need to logout");
    }
    throw error;
  }
};

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function IsRunningOverwolf(): boolean {
  return process.env.REACT_APP_BUILD_TARGET === BUILD_TARGET.OVERWOLF;
}

const { DESKTOP, INGAME } = WINDOW_NAMES;

export async function GetApplication(): Promise<string> {
  if (IsRunningOverwolf()) {
    let currentWindow = await getCurrentWindow();
    if (currentWindow === DESKTOP) return "OW_DESKTOP_CLIENT";
    if (currentWindow === INGAME) return "OW_INGAME_CLIENT";
  }

  const platform = Capacitor?.getPlatform();

  if (platform === "android") {
    return "ANDROID_CLIENT"
  }

  if (platform === "ios") {
    return "IOS_CLIENT"
  }

  return "WEBAPP_CLIENT";
}

export const sanitizeInput = (input: string): string => {
  return input?.toLowerCase().replace(/[\s\W_]+/g, ""); // Remove spaces, punctuation, and special characters
};

export function convertDistance(unit: string, distance: number): number {
  if (unit === 'miles') {
    return distance * 1.60934; // Convert miles to kilometers
  } else if (unit === 'km') {
    return distance / 1.60934; // Convert kilometers to miles
  } else {
    throw new Error("Invalid unit. Please use 'miles' or 'km'.");
  }
}

export function GetApplicationVersion(): string {
  return packageJson.version;
}
